import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ssh renew`}</strong>{` -- renew a SSH certificate using the SSH CA`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ssh renew <ssh-cert> <ssh-key> [--out=<file>]
[--issuer=<name>] [--password-file=<file>] [--force] [--offline]
[--ca-config=<file>] [--ca-url=<uri>] [--root=<file>]
[--context=<name>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ssh renew`}</strong>{` command renews an SSH Host Certificate
using `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/certificates"
      }}>{`step certificates`}</a>{`.
It writes the new certificate to disk - either overwriting `}<inlineCode parentName="p">{`ssh-cert`}</inlineCode>{` or
using a new file when the `}<strong parentName="p">{`--out`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{` flag is used. This command cannot
be used to renew SSH User Certificates.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`ssh-cert`}</inlineCode>{`
The ssh certificate to renew.`}</p>
    <p><inlineCode parentName="p">{`ssh-key`}</inlineCode>{`
The ssh certificate private key.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--out`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`, `}<strong parentName="p">{`--output-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The new certificate `}<inlineCode parentName="p">{`file`}</inlineCode>{`. Defaults to overwriting the `}<inlineCode parentName="p">{`ssh-cert`}</inlineCode>{` positional argument`}</p>
    <p><strong parentName="p">{`--provisioner`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`, `}<strong parentName="p">{`--issuer`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The provisioner `}<inlineCode parentName="p">{`name`}</inlineCode>{` to use.`}</p>
    <p><strong parentName="p">{`--provisioner-password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to decrypt the one-time token
generating key.`}</p>
    <p><strong parentName="p">{`--sshpop-cert`}</strong>{`=`}<inlineCode parentName="p">{`chain`}</inlineCode>{`
Certificate (`}<inlineCode parentName="p">{`chain`}</inlineCode>{`) in PEM format to store in the 'sshpop' header of a JWT.`}</p>
    <p><strong parentName="p">{`--sshpop-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Private key `}<inlineCode parentName="p">{`file`}</inlineCode>{`, used to sign a JWT, corresponding to the certificate that will
be stored in the 'sshpop' header.`}</p>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>
    <p><strong parentName="p">{`--offline`}</strong>{`
Creates a certificate without contacting the certificate authority. Offline mode
uses the configuration, certificates, and keys created with `}<strong parentName="p">{`step ca init`}</strong>{`,
but can accept a different configuration file using `}<strong parentName="p">{`--ca-config`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--ca-config`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The certificate authority configuration `}<inlineCode parentName="p">{`file`}</inlineCode>{`. Defaults to
$(step path)/config/ca.json`}</p>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the PEM `}<inlineCode parentName="p">{`file`}</inlineCode>{` used as the root certificate authority.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The context `}<inlineCode parentName="p">{`name`}</inlineCode>{` to apply for the given command.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Renew an ssh certificate overwriting the previous one:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh renew -f id_ecdsa-cert.pub id_ecdsa
`}</code></pre>
    <p>{`Renew an ssh certificate with a custom out file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh renew -out new-id_ecdsa-cer.pub id_ecdsa-cert.pub id_ecdsa
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      